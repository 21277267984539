import classnames from "classnames";
import { FocusEventHandler, SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import { FieldError } from "react-hook-form";
import { TranslationScopes } from "@finbackoffice/enums";
import Tooltip from "../tooltip/Tooltip";
import Translate, { ITranslateProps } from "../translate/Translate";
import styles from "./input-field.module.sass";

interface IProps<T extends string> {
    label?: string | ITranslateProps;
    iconClass?: string;
    innerIconClass?: string;
    wrapperClassname?: string;
    error?: Partial<FieldError> | { type: string; message: string };
    tooltip?: {
        render: string;
        variant?: string;
        condition?: string;
    };
    onInnerIconClick?: (e: SyntheticEvent) => void;
    onIconClick?: (e: SyntheticEvent) => void;
    required?: boolean;
    enableAutoComplete?: boolean;
    showFocus?: boolean;
    type?: T;
    disabled?: boolean;
    forwardRef?: (input: HTMLInputElement | null) => void;
    onChange?: (val: T extends "number" ? number : string) => void;
    onFocus?: FocusEventHandler<HTMLInputElement>;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    value?: T extends "number" ? number : string;
    name: string;
    placeholder?: string;
    testId?: string;
}

const Input = <T extends string>({
    label,
    iconClass,
    innerIconClass,
    wrapperClassname,
    error,
    tooltip,
    onInnerIconClick,
    onIconClick,
    required,
    enableAutoComplete,
    showFocus,
    forwardRef,
    name,
    onChange,
    onFocus,
    onBlur,
    value,
    placeholder,
    type,
    disabled,
    testId,
}: IProps<T>) => {
    const [autoComplete, setAutoComplete] = useState(enableAutoComplete ? "on" : "off");
    const inputRef = useRef<any>();

    useEffect(() => {
        if (!enableAutoComplete && navigator?.userAgent.includes("Chrome")) {
            setAutoComplete("new-password");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRefSet = useCallback(
        (input: HTMLInputElement | null) => {
            inputRef.current = input;
            forwardRef?.(input);
        },
        [inputRef, forwardRef],
    );

    useEffect(() => {
        if (showFocus && inputRef?.current.getBoundingClientRect().y < window.innerHeight) {
            inputRef.current.focus();
        }
    }, [showFocus]);

    const handleInputChange = useCallback(
        (e: SyntheticEvent<HTMLInputElement>) => {
            const val = type === "number" ? Number(e.currentTarget.value) : e.currentTarget.value;
            onChange?.(val as T extends "number" ? number : string);
        },
        [onChange, type],
    );

    return (
        <div className={wrapperClassname}>
            {iconClass && <i className={iconClass} onClick={onIconClick} />}
            {label && (
                <label htmlFor={name} className={styles.label}>
                    <Translate
                        tid={typeof label === "string" ? label : (label as ITranslateProps).tid}
                        namespace={
                            typeof label !== "string"
                                ? (label as ITranslateProps).namespace
                                : TranslationScopes.Common
                        }
                    />
                </label>
            )}
            <div
                className={classnames({
                    [styles.fieldError]: error,
                    required,
                })}>
                <input
                    type={type}
                    id={name}
                    name={name}
                    data-testid={testId}
                    className={styles.inputStyle}
                    ref={handleRefSet}
                    onChange={handleInputChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    value={value}
                    autoComplete={autoComplete}
                    placeholder={placeholder}
                    disabled={disabled}
                />
                {innerIconClass && <i className={innerIconClass} onClick={onInnerIconClick} />}
                {error && (
                    <span className={classnames(styles.error, "field-error")}>{error.message}</span>
                )}
                {tooltip && (
                    <Tooltip
                        targetRef={inputRef}
                        message={tooltip.render}
                        variant={tooltip.variant}
                        condition={tooltip.condition}
                    />
                )}
            </div>
        </div>
    );
};

export default Input;
